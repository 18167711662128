export const globals = {
  mainDomain: 'tr-line.ru',

  numericMask: {
    alias: 'numeric',
  },
  dateMask: {
    regex:
      '^([0][1-9]\\.([0][1-9])|([1][0-2]))|([1-2][0-9]\\.([0][1-9])|([1][0-2]))|([3][0]\\.([0][13456789])|([1][0-2]))|([3][1]\\.([0][13578])|([1][0])|([1][2]))\\.\\d{4}$',
    autoUnmask: false,
  },
  datetimeMask: {
    regex:
      '^([0][1-9]\\.([0][1-9])|([1][0-2]))|([1-2][0-9]\\.([0][1-9])|([1][0-2]))|([3][0]\\.([0][13456789])|([1][0-2]))|([3][1]\\.([0][13578])|([1][0])|([1][2]))\\.\\d{4}$ [0-2][0-9]:[0-5][0-9]',
    autoUnmask: false,
  },
  phoneMask: '+7 (999) 999-9999',
  innMask: '9999999999',
  innIPMask: '999999999999',
  bikMask: {
    mask: '9{9}',
  },
  bankAccountMask: {
    mask: '9{20}',
  },
  coordinatesMask: {
    regex:
      '^(-?(([0-9]\\.\\d{6})|([1-8][0-9]\\.\\d{6})|([9][0]\\.[0]{6}))), (-?(([0-9]\\.\\d{6})|([1-9][0-9]\\.\\d{6})|([1][0-7][0-9]\\.\\d{6})|([1][8][0]\\.[0]{6})))$',
    autoUnmask: false,
  },

  dadata_token: 'f6af6d505754bd736dd437c2e4c472f01bb670f5', // Токен для запроса к сервису юр лиц

  tnved_type_id: 139, // id справочника "Группа продукции ЕАЭС"
  users_type_id: 53, // id "Пользователей"

  lab_accred: 1938, // id типа испытаний "в аккредитованной в ЕАЭС лаборатории"
  applicant_type_manufacturer: 1460, // id типа декларанта "Изготовитель"

  default_country: 'default-country', // Выбранная страна по умолчанию "Россия"
  entity_legal_subject_type: 24103, // Выбранный тип лица "Юридическое лицо"
  individual_legal_subject_type: 24104, // Выбранный тип лица "Индивидуальный предприниматель"
  foreign_legal_subject_type: 24105, // Выбранный тип лица "Иностранное лицо"
  phys_subject_type: 24106, // Выбранный тип лица "Физическое лицо"
  payment_invoice: 24616, // id - способа оплаты "Счет для юридических лиц"
  payment_yandex: 600, // id - способа оплаты "Yandex Kassa"
  order_status_payment: 194, // id - статуса оплаты заказа "Оплачивается"

  product_type_batch: 1936, // id - тип продукции "Партия"
  product_type_single_item: 1768, // id - тип продукции "Единичное изделие"

  non_standard_decl_doc_name: 'non-standard-decl-doc-name', // guid "Новое наименование" в справочнике "Наименования документов декларации" "
  application_decl_doc_name: 'application-decl-doc-name', // guid "Заявление о регистрации декларации" в справочнике "Наименования документов декларации" "

  guids_countrys: 10, // id - справочника "Страны"
  guids_contractor: 'emarket-legalperson', // id - справочника "Юридическое лицо"
  guids_applications: 126, // id - справочника "Заявка"
  guids_region_rf: 131, // id - справочника "Регионы РФ"
  guids_legal_form: 133, // id - справочника "Правовая флрма"
  guids_contractor_type: 134, // id - справочника "Тип декларанта"
  guids_reglaments: 135, // id - справочника "Технические регламенты"
  guids_reglament_fgis: 141, // id - справочника "Регламент ФГИС"
  guids_declaration: 142, // id - справочника "Декларации"
  guids_product_type: 144, // id - справочника "Тип продукции"
  guids_protocols: 145, // id - справочника "Протоколы"
  guids_labs: 146, // id - справочника "Лаборатории"
  guids_status_labs: 147, // id - справочника "Лаборатории"
  guids_fgis_product_group: 151, // id - справочника "Группа продукции ЕАЭС"
  guids_annex_freeform: 155, // id - справочника "Приложение "Свободная форма"
  guids_standarts: 153, // id справочника "Стандарты"
  guids_customs_declarations: 'customs_declarations', // GUID - справочника "Таможеные декларации"
  guids_additional_product: 157, // id - справочника "Дополнительная продукция"
  guids_legal_subject_type: 158, // id - справочника "Тип лица (заявителя/изготовителя)"
  guids_accordingly_document: 159, // id - справочника "Документ о соответствии"
  guids_applicant_doc_type: 'applicant-doc-type', // GUID - справочника "Категории иных документов представленных заявителем"
  guids_others_applicant_doc: 'others-applicant-doc', // GUID - справочника "Иные документы, представленные заявителем"
  guids_legal_person_affiliate: 'legal_person_affiliate', // GUID - справочника "Филиалы Юр лиц"
  guids_declaration_document: 'declaration_document', // GUID - справочника "Документы декларации"
  guids_name_documents_declaration: 'name_documents_declaration', // GUID - справочника "Наименование документов декларации"
  guids_type_scan_copy: 'type_scan_copy', // GUID - справочника "Тип скан-копии"
  guids_users: 'users-user', // GUID - справочника "Пользователи"
  guids_orders: 'emarket-order', // GUID - справочника "Заказ"
  guids_exemption_letter: 'exemption_letter', // GUID - справочника "Отказные письма"
  guids_invoices: 'invoices', // GUID - справочника "Счета"

  guids_decl_type: 'decl_type', // GUID - справочника "Тип декларации"
  guids_certification_body: 'certification_body', // GUID - справочника "Орган сертификации"
  manufacturing_lab_type_id: 1785, // ID Типа лаборатории "Испытания проводились в производственной лаборатории"

  guids_single_list: 172, // GUID - справочника "Единый перечень продукции РФ"
  guids_okpd2: 173, // GUID - справочника "ОКПД2"

  manager_group_id: 598, // id группы пользователи Менеджеры
  client_group_id: 594, // id группы пользователей Клиенты

  payment_type_invoice: 'invoicePayment', // Тип оплаты "Счетом"

  IC_DOCS_INIT_GROUP_KEY: 'init_ic_docs', // Ключ основной группы документов ИК
};
